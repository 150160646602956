import * as React from "react"
import Layout from "../components/layout"
import resume from "../static/blank.pdf"
import "../styles/contact.css"

const ContactPage = () => {
    return (
        <Layout pageTitle="Peter Mason | Contact Me">
            <h1>Contact Me</h1>
            <div class="width-container">
                <div class="description">
                    If you'd like to work with me, are particularly interested in a project or blog post, or just want to say hi, feel free to shoot me a message below.
                </div>
                <div class="description">
                    You can also check out my résumé <a href={resume} download="Peter Mason Résumé.pdf">here</a>.
                </div>
                {/* TODO - Add social media icons here */}
                <form action="https://formsubmit.co/pm3.141@gmail.com" method="POST"> {/* https://formsubmit.co/el/rawidi */}
                    <input type="text" name="Name" class="input-block contact-name" placeholder="Name" required />
                    <input type="email" name="Email" class="input-block contact-email" placeholder="Email Address" required />
                    <input type="text" name="Subject" class="input-block subject" placeholder="Subject" />
                    <textarea type="text" name="Message" class="input-block text-area" placeholder="Your Message" rows="7" required />
                    <button type="submit" class="submit-button">Send</button>

                    <input type="hidden" name="_url" value="https://www.petermason.me/contact/" />
                    <input type="hidden" name="_subject" value="New message from petermason.me!" />
                    <input type="hidden" name="_captcha" value="false" />
                    <input type="text" name="_honey" class="hidden" />
                    <input type="hidden" name="_next" value="https://petermason.me/thankyou" />
                </form>
            </div>
        </Layout>
    )
}

export default ContactPage

